// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-afspraak-maken-tsx": () => import("./../../../src/pages/afspraak-maken.tsx" /* webpackChunkName: "component---src-pages-afspraak-maken-tsx" */),
  "component---src-pages-binnenkort-tsx": () => import("./../../../src/pages/binnenkort.tsx" /* webpackChunkName: "component---src-pages-binnenkort-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-online-bril-passen-tsx": () => import("./../../../src/pages/online-bril-passen.tsx" /* webpackChunkName: "component---src-pages-online-bril-passen-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-winkel-tsx": () => import("./../../../src/pages/winkel.tsx" /* webpackChunkName: "component---src-pages-winkel-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/Service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-store-tsx": () => import("./../../../src/templates/Store.tsx" /* webpackChunkName: "component---src-templates-store-tsx" */)
}

